.dashboard-auth {
    width: 100%;
    height: 100%;
    background: #7e32ca;
}

.dashboard-auth .auth-wrapper {
    width: 480px;
    height: auto;
}

.dashboard-auth-title {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

input[type="checkbox"] {
    margin: 0 10px 0 0;
}

.table {
    width: 100%;
    height: auto;
    margin-top: 35px;
    font-family: Montserrat;
}

.table table {
    width: 100%;
    border-collapse: collapse;
    border: 0;
}

.table table td,
.table table th,
.table table thead,
.table table tbody {
    border: 0;
}

.table table td {
    font-size: 14px;
    line-height: 17px;
    color: #505050;
    padding-top: 25px;
}

.table table td button {
    height: 30px;
    width: auto;
    border: 1px solid #7e32ca;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 10px;
    font-size: 14px;
    line-height: 17px;
    color: #7e32ca;
    outline: none;
}

.table th {
    height: 40px;
    text-align: start;
    font-size: 14px;
    line-height: 17px;
    color: #9598aa;
    font-weight: 400;
}

.table .tr {
    height: 70px !important;
}

.table .tr:nth-child(even) {
    background: transparent;
    border-radius: 20px;
}

.table .tr:nth-child(odd) {
    background: rgba(126, 50, 202, 0.06);
    border-radius: 4px;
}

.signup-bg {
    background: url("./assets/Rectangle.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.tab-title {
    color: #7E32CA;
    font-family: Graphik, Serif;
    font-weight: 500;
    line-height: 23px;
}

.tab-active {
    border: 2px solid #7E32CA;
    box-shadow: 0 0 0 4px #7E32CA1A;
}

.tab-value {
    color: rgba(51, 51, 51, 0.6);
    font-family: Montserrat, serif;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
}

.results-container {
    background: radial-gradient(circle, rgba(226,240,255,1) 0%, rgba(250,239,245,1) 100%);
}

.filter-title {
    color: #3C4859;
    font-family: Graphik, Serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}


input[type="checkbox"], label {
    cursor: pointer;
    margin-bottom: 0 !important;
}

.active-tab {
    position: absolute;
    z-index: 10;
    width: 30px;
    height: 30px;
    background-color: #FFFFFF;
    border-radius: 8px;
}

.active-tab-img {
    width: 22px;
    height: 22px;
    transition: height 300ms ease-in, width 300ms ease-in;
}


.tab-right {
    transform: translateX(4px);
    transition: transform 300ms;
}

.tab-left {
    transform: translateX(-26px);
    transition: transform 300ms;
}

.tab-img {
    cursor: pointer;
    z-index: 15;
}

.center-tab {
    padding: 14px 16px;
    display: flex;

}

input[type="checkbox"], input[type="radio"] {
    display: none;
}

input[type="checkbox"] + label, input[type="radio"] + label {
    margin-left: 32px;
}

input[type="checkbox"]:not(:disabled) + label:after {
    position: absolute;
    left: 32px;
    top: 14px;
    width: 20px;
    height: 20px;
    border: 1px solid #7E32CA;
    border-radius: 4px;
    content: '';
}

input[type="checkbox"]:checked + label:after {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 32px;
    top: 14px;
    width: 20px;
    height: 20px;
    border: 2px solid #7E32CA;
    border-radius: 4px;
    content: url("/src/assets/svg/check-mark.svg");
    background-color: #7E32CA;
    transition: color 1s ease-in;
}

input[type="checkbox"]:disabled + label:after {
    position: absolute;
    left: 32px;
    top: 14px;
    width: 20px;
    height: 20px;
    border: 1px solid rgba(60, 72, 89, 0.2);
    border-radius: 4px;
    content: '';
}

input[type="radio"] + span{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 24px;
    width: 15px;
    height: 15px;
    border: 1px solid #7E32CA;
    border-radius: 50%;
}

input[type="radio"]:checked + span:after {
    width: 10px;
    height: 10px;
    border: 2px solid #7E32CA;
    border-radius: 50%;
    content: '';
    background-color: #7E32CA;
    transition: color 1s ease-in;
}
