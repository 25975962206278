$breakpoint-tablet: 529px;

// Password strength meter color for the different levels
$strength-colors: (darkred, orangered, orange, yellowgreen, green);

// Gap width between strength meter bars
$strength-gap: 6px;

@import '../src/assets/fonts/Montserrat/montserrat.css';
@import '../src/assets/fonts/Avenir/avenir.css';

body {
  font-family: Montserrat !important;
}

.strength-meter {
  position: relative;
  height: 3px;
  background: #ddd;
  margin: 13px 0;
  border-radius: 2px;

  // Dynamically create the gap effect
  &:before,
  &:after {
    content: '';
    height: inherit;
    background: transparent;
    display: block;
    border-color: #fff;
    border-style: solid;
    border-width: 0 $strength-gap 0;
    position: absolute;
    width: calc(20% + #{$strength-gap});
    z-index: 10;
  }

  // Dynamically create the gap effect
  &:before {
    left: calc(20% - #{($strength-gap / 2)});
  }

  // Dynamically create the gap effect
  &:after {
    right: calc(20% - #{($strength-gap / 2)});
  }
}

.strength-meter-fill {
  background: transparent;
  height: inherit;
  position: absolute;
  width: 0;
  border-radius: inherit;
  transition: width 0.5s ease-in-out, background 0.25s;

  // Dynamically generate strength meter color styles
  @for $i from 1 through 5 {
    &[data-strength='#{$i - 1}'] {
      width: (20% * $i);
      background: nth($strength-colors, $i);
    }
  }
}

//Modal styles
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  padding: 10%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .modal-main {
    position: fixed;
    background: white;
    width: 40%;
    height: auto;
    border-radius: 4px;
    background: #ffffff;
    overflow: hidden;
    box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15),
      0px 0px 0px rgba(63, 63, 68, 0.05);
    .close-icon {
      position: absolute;
      right: -10px;
      top: 5px;
      font-size: 24px;
      cursor: pointer;
      color: #c4c4c4;
    }
  }
}

.password-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  padding: 10%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .modal-main {
    position: fixed;
    background: white;
    width: 40%;
    height: auto;
    border-radius: 4px;
    background: #ffffff;
    overflow: hidden;
    box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15),
      0px 0px 0px rgba(63, 63, 68, 0.05);
    .close-icon {
      position: absolute;
      right: -10px;
      top: 5px;
      font-size: 24px;
      cursor: pointer;
      color: #c4c4c4;
    }
  }
}
.spinner-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  padding: 10%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 5;
}
.modal.display-block,
.spinner-modal.display-block {
  display: flex;
}
.modal.display-none,
.spinner-modal.display-none {
  display: none;
}

.close-btn__wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 49px;

  .btn-close-modal {
    width: 165px;
    height: 50px;
    background: #f2efff;
    border-radius: 4px;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: 0;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 143.16%;
    color: #7e32ca;
  }
}

.modal-instruction__wrapper {
  padding: 0 47px;
  .w-50 {
    width: 50%;
  }

  svg {
    margin-right: 8px;
    width: 80px;
    height: 80px;
    flex: 1;
  }

  .instruction-item {
    margin-bottom: 56px;
    margin-right: 5%;

    padding: 0;

    .content__wrapper {
      flex: 1;
      h4 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: #616095;
      }

      p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 17px;
        color: #333333;
      }
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .modal-instruction__wrapper {
    padding: 0;
    .w-50 {
      width: 100% !important;
    }
  }

  .modal {
    padding: 0;
  }
}
