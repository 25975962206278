.ant-pagination-item {
  border: 0px solid transparent !important;
  border-radius: 100px !important;
  background-color: rgba(126, 50, 202, 0.04) !important;
}

.ant-pagination-item-active {
  background-color: rgba(126, 50, 202, 0.04) !important;
  color: white !important;
  border: 1px solid #4943a2 !important;
  color: #7e32ca !important;
  font-weight: 800 !important;
}
