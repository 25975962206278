@font-face {
    font-family: "Gotham";
    src: url("./assets/fonts/Gotham/Gotham-Light.otf") format("opentype");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Gotham";
    src: url("./assets/fonts/Gotham/Gotham-Black.otf") format("opentype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Gotham";
    src: url("./assets/fonts/Gotham/Gotham-Medium.otf") format("opentype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Gotham";
    src: url("./assets/fonts/Gotham/Gotham-Bold.otf") format("opentype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Graphik";
    src: url("./assets/fonts/graphik/Graphik/Graphik-Light.otf") format("opentype");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Graphik";
    src: url("./assets/fonts/graphik/Graphik/Graphik-Regular.otf") format("opentype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Graphik";
    src: url("./assets/fonts/graphik/Graphik/Graphik-Bold.otf") format("opentype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Graphik";
    src: url("./assets/fonts/graphik/Graphik/Graphik-Super.otf") format("opentype");
    font-weight: 900;
    font-style: normal;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: "Gotham", "Graphik", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f9f9f9 !important;
}

.signup-bg {
    background: url(./assets/Rectangle.png);
    background-size: cover;
    background-repeat: no-repeat;
}

.primary {
    color: #7e32ca;
    font-weight: bold;
    cursor: pointer;
}

.bold {
    font-weight: bold;
}

.border-bottom {
    border-bottom: 1px solid #f1f1f1;
}

.search_term {
    color: #333333;
    font-size: 18px;
    display: inline-block;
}

ul li {
    list-style-type: none;
    cursor: pointer;
}

.details-panel {
    height: 400px;
    max-height: max-content;
    overflow-y: scroll;
    margin-bottom: 157px;
}

.result_cards {
    width: 100%;
    margin-bottom: 57px;
}

.card-item {
    width: 100%;
    margin-bottom: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    padding: 17px 0px;
    width: 100%;
}

.grid_cards .card-item {
    width: 307px;
}

.list_cards li {
    justify-content: space-between;
}

.grid_cards .details {
    margin-left: 8px;
    width: 70%;
}

.list_cards {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.list_cards .details {
    width: 90%;
    margin-left: 22px;
}

.handle_avatar {
    margin-left: 14px;
    border-radius: 50%;
    background: rgba(126, 50, 202, 0.15);
    text-align: center;
    text-transform: uppercase;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 12px;
    color: #7E32CA;
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

p.user_name {
    font-family: Montserrat;
    font-weight: normal;
    font-size: 20px;
    color: #333333;
    line-height: 24px;
}

p.user_handle {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #7E32CA;
}

.interactions {
    color: #7E32CA;
    font-size: 32px;
    font-weight: 500;
    font-family: Montserrat;
    line-height: 39px;
}

.list_cards .details {
}

.list_cards .details p.user_name {
    width: 50%;
}

.list_cards .details p.user_handle {
    width: 25%;
}

.list_cards .details p.interactions {
    width: 25%;
    justify-self: center;
    text-align: center;
}

.grid_cards .details p.user_handle {
    font-size: 16px;
    line-height: 19.5px;
}

.grid_cards .details p.user_name {
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
}

.grid_cards {
    padding: 16px 0;
    display: grid;
    column-gap: 20px;
    row-gap: 28px;
    grid-template-columns: repeat(1, 1fr);
    border-radius: 10px;

}

.grid_cards a {
    justify-self: start;
}


@media only screen and (max-width: 1024px) {
    .grid_cards a {
        justify-self: center;
    }
}

@media only screen and (min-width: 768px) {
    .grid_cards {
        grid-template-columns: repeat(2, 1fr);
    }
}


@media only screen and (min-width: 1240px) {
    .grid_cards {
        grid-template-columns: repeat(3, 1fr);
    }

}

.bg-yellow {
    background: yellow !important;
}

.flex {
    display: flex;
}

.flex-column {
    flex-direction: column !important;
}

.flex-row {
    flex-direction: row !important;
}

.flex-between {
    justify-content: space-between;
}

.align-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.w-inherit {
    width: inherit !important;
}

.w-100 {
    width: 100% !important;
}

.w-100-m {
    width: 100% !important;
}

.nav-link {
    font-family: Graphik;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    color: #c4c4c4;
}

.nav-link:hover,
.nav-link:active,
.nav-link:focus {
    font-family: Graphik;
    font-style: normal;
    font-weight: bold;
    color: #7e32ca;
}

.nav-link.active {
    font-family: Graphik;
    font-style: normal;
    font-weight: bold;
    color: #7e32ca;
}

.load__more {
    padding: 17px 36px;
    background: #fff;
    border-radius: 4px;
    font-family: Graphik;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #7E32CA;
    margin: 0 auto 157px auto;
    border: 0;
    outline: none;
    text-transform: uppercase;
}

.outdegree-count,
.indegree-count {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 17px;
    text-align: right;
    letter-spacing: 0.03em;
    color: #7e32ca;
    margin-top: 12px;
}

.block-cursor {
    cursor: not-allowed !important;
}

.error {
    border: 1px solid red;
}

.my-modal {
    background: #fff;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 300px;
    height: 300px;
    box-shadow: 0 5px 10px 2px rgba(195, 192, 192, 0.5);
    padding: 20px;
    text-align: center;
}

.modal-close {
    position: absolute;
    right: 6px;
    top: 9px;
    cursor: pointer;
    border: none;
    font-size: 20px;
}

.close-modal {
    display: block;
    height: 20px;
    width: 20px;
    font-weight: bold;
    background: grey;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-toggle-button {
    cursor: pointer;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-top: 20px;
}

.modal-toggle-button:focus {
    outline: none;
}

.bold {
    font-weight: bold;
}

.cube-1 {
    left: 307px;
    top: 151px;
    position: absolute;
}

.cube-3 {
    position: absolute;
    left: 307px;
    top: 357px;
}

.cube-11 {
    position: absolute;
    left: 1194px;
    top: 88px;
}

.cube-12 {
    position: absolute;
    left: 575px;
    top: 71px;
}

.cube-22 {
    position: absolute;
    right: 174px;
    top: 213px;
}

.cube-group-1 {
    position: absolute;
    right: 313px;
    top: 341px;
}

.cube-greyed-top-sm {
    position: absolute;
    left: 395px;
    top: 133px;
}

.cube-greyed-btm-bg {
    position: absolute;
    left: 244px;
    top: 248px;
}

.cube-greyed-top-bg {
    position: absolute;
    left: 1167px;
    top: 129px;
}

.cube-greyed-btn-sm {
    position: absolute;
    right: 27%;
    top: 314px;
}

.yellow-cube-up {
    position: absolute;
    left: 79px;
    top: 50px;
}

.yellow-cube-down {
    position: absolute;
    right: 481px;
    top: 500px;
}

.big-cone-right {
    position: absolute;
    right: 0;
    top: 217px;
}

.cube-small-down {
    position: absolute;
    top: 500px;
    left: 341px;
}

.cube-small-up {
    position: absolute;
    right: 612px;
    top: 129px;
}

input {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 29px;
}

.hide-icon {
    display: none;
}

.no-position {
    position: unset !important;
}

::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #e5e5e5;
}

:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #e5e5e5;
}

::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #e5e5e5;
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #e5e5e5;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #e5e5e5;
}

::placeholder {
    /* Most modern browsers support this now. */
    color: #e5e5e5;
}

.formWrapper {
    height: 65px;
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 8px 20px;
    border: 1px solid rgba(130, 127, 152, 0.4);
    margin-bottom: 25px;
}

.data-access {
    margin-bottom: 65px;
}

.password-modal {
}

.reset-modal {
    position: relative;
    padding: 20px 20px;
    z-index: 10;
}

.reset-modal-wrapper {
}

.reset-auth {
    display: flex;
    flex-direction: column;
}

.close-modal {
    position: absolute;
    right: 0;
    top: 0;
    width: 33.54px;
    height: 33.54px;
    border-radius: 50%;
    background: #f5f8fa;
}

.formControl {
    background: rgba(244, 246, 248, 0.3);
    border: 1px solid #e1e1e1;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    height: 65px;
    margin-bottom: 30px;
}

.formControl .form-input {
    width: inherit !important;
    height: inherit !important;
}

.formControl .form-input input {
    width: inherit !important;
    height: inherit !important;
    border: none;
    outline: none;
    padding: 0 15px;
    font-weight: bold;
    background: none;
}

.formControl .form-input label {
    text-align: justify;
    padding: 0 15px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #637381;
}

.password-error p {
    color: red;
}

.data-mgt h2,
.data-access h2,
.password-title {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    color: #333333;
    margin: 0;
    padding: 0;
}

.data-mgt p,
.data-access p,
.password-sub {
    font-family: Gotham;
    font-style: normal;
    font-weight: lighter;
    color: #333333;
    padding: 0;
}

.mobile-show {
    display: block !important;
}

.desktop-hidden {
    display: none !important;
}

.mobile-hidden {
    display: inline-flex !important;
}

.into-header {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    margin: 40px 0;
}


@media only screen and (max-width: 529px) {
    .center-tab {
        margin: 0;
    }

    .mobile__no_center {
        width: inherit;
    }

    ul.result_cards {
        padding: 0;
        margin: 0;
    }

    .nav li {
        white-space: nowrap;
    }

    .nav-link {
        white-space: normal;
    }

    .popup_modal {
        width: 89% !important;
        padding: 0;
        height: auto !important;
        margin: 0 20px 0 20px !important;
        position: absolute !important;
        left: 0;
    }

    .cube-1,
    .cube-3,
    .cube-11,
    .cube-22,
    .cube-12,
    .cube-group-1,
    .cube-greyed-top-sm,
    .cube-greyed-top-bg,
    .cube-greyed-btm-bg,
    .cube-greyed-btn-sm,
    .cube-small-down,
    .cube-small-up,
    .yellow-cube-up,
    .yellow-cube-down,
    .big-cone-right {
        position: relative;
        display: none;
    }

    .modal-instruction__wrapper {
        display: flex;
        overflow: scroll;
    }

    .modal-main {
        width: 100% !important;
    }

    .modal-item {
        width: 100% !important;
    }

    .w-100-m {
        width: 100% !important;
    }

    .mobile-hidden {
        display: none !important;
    }

    .desktop-hidden {
        display: flex !important;
    }

    .into-header {
        text-align: center;
        margin-left: 40px;
    }
}

.pill {
    cursor: pointer;
    padding: 9px;
    width: max-content;
    border-radius: 8px;
    gap: 12px;
    display: flex;
    align-items: center;
}

.active-pill {
    background-color: #FFFFFF;

}
